import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import GeneralMixin from '@/mixins/GeneralMixin';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      title: 'Chasdei Lev - CRM | Login',
    },
    beforeEnter: (to, from, next) => {
      console.log(store.getters);
      if (store.getters.IS_AUTHENTICATED ) {
        next({ name: 'Home' }); // go to Home Page If already logged-in
      }
    },
  },
  {
    path      : '/home',
    name      : 'Home',
    component : ()=> import('@/views/LandingPageView.vue'),
    meta : {
      requiresAuth : true,
      modules      : ['ADMIN-PORTAL','DISTRIBUTION','INVENTORY-RECEIVING','DISPATCHER-LOADING','STOCK-INVENTORY'],
      permission   : 'read',
      title        : 'Chasdei Lev - CRM | Home'
    }
  },
  {
    path      : '/trailer-loading',
    name      : 'TrailerLoading',
    component : () => import('@/views/TrailerLoading.vue'),
    meta : {
      requiresAuth : true,
      modules      : 'DISPATCHER-LOADING',
      permission   : 'read',
      title        : 'Chasdei Lev - CRM | Trailer Loading'
    }
  },
  {
    path      : '/palletts',
    name      : 'Paletts',
    component : ()=> import('@/views/Paletts.vue'),
    meta : {
      requiresAuth : true,
      modules      : 'DISTRIBUTION',
      permission   : 'read',
      title        : 'Chasdei Lev - CRM | Scan Pallets'
    }
  },
  {
    path      : '/order-pickup',
    name      : 'OrderPickup',
    component : ()=> import('@/views/OrderPickup.vue'),
    meta : {
      requiresAuth : true,
      modules      : 'DISTRIBUTION',
      permission   : 'read',
      title        : 'Chasdei Lev - CRM | Scan Order Pickups'
    }
  },
  {
    path: '/receive-stock',
    name: 'ReceiveStock',
    component: ()=> import('@/views/ReceiveStock.vue'),
    meta : {
      requiresAuth : true,
      modules      : 'INVENTORY-RECEIVING',
      permission   : 'read',
      title        : 'Chasdei Lev - CRM | Receive Stock'
    }
  },
  {
    path      : '/scan-pick',
    name      : 'ScanPick',
    component : ()=> import('@/views/ScanPick.vue'),
    meta : {
      requiresAuth : true,
      modules      : 'DISTRIBUTION',
      permission   : 'read',
      title        : 'Chasdei Lev - CRM | Scan Pick'
    }
  },
  {
    path: '/admin',
    component: ()=> import('@/views/Admin.vue'),
     meta : {
      requiresAuth : true,
      modules      : ['ADMIN-PORTAL','DISTRIBUTION','INVENTORY-RECEIVING'],
      permission   : 'read',
      title        : 'Chasdei Lev - CRM | Admin'
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: ()=> import('@/components/dashboard/Dashboard.vue'),
        meta : {
          modules      : 'ADMIN-PORTAL',
          permission   : 'read',
        },
      },
      {
        path      : 'orders',
        name      : 'Orders',
        component : ()=> import('@/components/orders/Listing.vue'),
        meta : {
          modules      : ['ADMIN-PORTAL','DISTRIBUTION'],
          permission   : 'read',
        },
      },
      {
        path: 'order-pallets',
        name: 'OrderPallets',
        component: ()=> import('@/components/orders/OrderPallets.vue'),
        meta : {
          modules      : ['ADMIN-PORTAL','DISTRIBUTION'],
          permission   : 'read',
        },
      },
      {
        path: 'order-pickups',
        name: 'Pickups',
        component: ()=> import('@/components/orders/Pickups.vue'),
        meta : {
          modules      : ['ADMIN-PORTAL','DISTRIBUTION'],
          permission   : 'read',
        },
      },
      {
        path: 'labels',
        name: 'Labels',
        component: ()=> import('@/views/Labels.vue'),
        meta : {
          modules      : 'ADMIN-PORTAL',
          permission   : 'read',
        },
      },
      {
        path: 'vendors',
        name: 'Vendors',
        component: ()=> import('@/views/Vendors.vue'),
        meta : {
          modules      : ['ADMIN-PORTAL','INVENTORY-RECEIVING'],
          permission   : 'read',
        },
      },
      {
        path: 'employees',
        name: 'Employees',
        component: ()=> import('@/views/Employees.vue'),
        meta : {
          modules      : ['ADMIN-PORTAL','DISTRIBUTION'],
          permission   : 'read',
        },
      },
      {
        path: 'products',
        name: 'Products',
        component: ()=> import('@/views/Products.vue'),
        meta : {
          modules      : ['ADMIN-PORTAL','INVENTORY-RECEIVING'],
          permission   : 'read',
        },
      },
      {
        path: 'warehouses',
        name: 'Warehouses',
        component: ()=> import('@/views/Warehouses.vue'),
        meta : {
          modules      : 'ADMIN-PORTAL',
          permission   : 'read',
        },
      },
      {
        path: 'stocks',
        name: 'Stocks',
        component: ()=> import('@/views/Stocks.vue'),
        meta : {
          modules      : ['ADMIN-PORTAL','INVENTORY-RECEIVING'],
          permission   : 'read',
        },
      },
      {
        path: 'distribution-requests',
        name: 'DistributionRequests',
        component: ()=> import('@/views/DistributionRequests.vue'),
        meta : {
          modules      : ['ADMIN-PORTAL','DISTRIBUTION'],
          permission   : 'read',
        },
      },
      {
        path: 'listing',
        name: 'AdminListing',
        component: ()=> import('@/components/admins/Listing.vue'),
        meta : {
          modules      : 'ADMIN-PORTAL',
          permission   : 'read',
        },
      },
      {
        path: 'user-roles',
        name: 'UserRole',
        component: ()=> import('@/views/RolesPermissionView.vue'),
        meta : {
          modules      : 'ADMIN-PORTAL',
          permission   : 'read',
        },
      },
      {
        path: 'settings',
        name: 'Settings',
        component: ()=> import('@/views/Settings.vue'),
        meta : {
          modules      : ['ADMIN-PORTAL'],
          permission   : 'read',
        },
      },
      {
        path: 'manage-audio',
        name: 'ManageAudio',
        component: ()=> import('@/views/ManageAudio.vue'),
        meta : {
          modules      : ['ADMIN-PORTAL'],
          permission   : 'read',
        },
      },
      {
        path      : 'trailers',
        name      : 'Trailers',
        component : ()=> import('@/views/Trailers.vue'),
        meta : {
          modules      : ['ADMIN-PORTAL','DISTRIBUTION'],
          permission   : 'read',
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

/*Validate the LOGGED IN STATE*/
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.IS_AUTHENTICATED && to.name !== 'Login') {
      next({ name: 'Login' }); // go to Login Page
    } else {
      if(GeneralMixin.methods.hasPermission(to.meta.modules,to.meta.permission))
      {
        next();// go to wherever I'm going
      }
      else  
      {
        console.log('Sorry, This page needs permissions...');
      }
    }
  } else {
    next(); // does not require auth, make sure to always call next()! //Login Page
  }

  // Set the document title based on the meta title of the matched route
  const pageTitle = to.meta.title || 'Chasdei Lev - CRM'; // Set a default title if none provided
  document.title = pageTitle;
});

export default router;
