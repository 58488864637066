export default {
  data() {
    return {
      paginationLengths: {}, // Stores pagination for each tab
    };
  },
  methods: {
    getPaginationLength(pageName, tabName) {
      const pageSettings = this.$store.state.PAGE_SETTINGS[pageName]
      const tabSettings = pageSettings?.[tabName]
      return tabSettings?.page_length || 10 // Default to 10
    },

    updatePaginationLength(pageName, tabName, length) {
      this.$store.dispatch('updatePageLength', {
        pageName: `${pageName}.${tabName}`,
        pageLength: length,
      });
    },
  },
};
