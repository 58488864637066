import store from '@/store';
export default {
  methods: {
    hasPermission(moduleNames, pName = null) {
      if (store.getters.CURRENT_USER.is_super_admin === 1) {    //There are 4 situations so 4 if statments used
        return true;
      }
      if (typeof moduleNames === 'string' && pName === null) {
        return this.hasPermissionForModule(moduleNames); //true or false
      }

      if (typeof moduleNames === 'string' && pName !== null) {
        return this.hasPermissionForModule(moduleNames, pName); 
      }
      if (Array.isArray(moduleNames) && pName === null) {
        return moduleNames.some(module => this.hasPermissionForModule(module)); // Check for at least one module
      }
      if (Array.isArray(moduleNames) && pName !== null) {
        return moduleNames.some(module => this.hasPermissionForModule(module, pName)); // Check for permission in at least one module
      }
    },
    hasPermissionForModule(module, pName = null) {
      const rolePermissions = store.getters.CURRENT_USER_PERMISSIONS;
      const moduleObj = rolePermissions.permissions.find(mod => mod.module_name === module);

      if (!moduleObj) {
        return false;
      }
      if (pName === null) {
        return true;
      }
      if(pName != null) {
        return moduleObj.permission && moduleObj.permission.includes(pName); //true or false
      }
    }
  }
}
