<template>
  <v-app>
    <!-- IF USER IS NOT LOGGED IN -->
    <login-view v-if="!isLoggedIn"></login-view>

    <!-- If USER IS LOGGED IN -->
    <v-main v-else>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
    'login-view': () => import('@/views/LoginView')
  },
  data: () => ({

  }),
  computed: {
    isLoggedIn() {
      console.log(this.$store.getters);
      return this.$store.getters.IS_AUTHENTICATED
    },
  },
};
</script>
