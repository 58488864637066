import axios from 'axios'
import store from '@/store'
import router from '@/router'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Accept'       : 'application/json',
    'Content-Type' : 'application/json',
  },
})

//set Auth Headers in request
axiosInstance.interceptors.request.use( config => {
    if(store.getters.ACCESS_TOKEN !== undefined) {
      config.headers.Authorization = `Bearer ${store.getters.ACCESS_TOKEN}`
    }
    return config
  },
  (error) => Promise.reject(error) 
)


//send response as it is 
axiosInstance.interceptors.response.use(
response => response,
error => {
  console.log(error)
  // Reject promise if usual error
  if(error.response.status !== 401) {
    return Promise.reject(error)
  }


  //handle UnAuthorized Access
  if(error.response.status == 401) {
    if (router.currentRoute.name == 'Login') {
      return Promise.reject(error)
    }
    else{
      //PENDING : SEND back the Logout Request
      localStorage.clear()
      window.location.href= '/'
    }
  }
  
})

export default axiosInstance