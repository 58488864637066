import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from '@/plugins/axios'
Vue.config.productionTip = false

import CofirmDelete from '@/components/shared/ConfirmDelete'
Vue.component('confirm-delete',CofirmDelete)


// Set the Authorization header with the token if it exists
const token = localStorage.getItem('auth_token')
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
} else {
  // Optionally, you can remove the Authorization header if no token is available
  delete axios.defaults.headers.common['Authorization']
}

import '@/assets/scss/style.scss'

import GeneralMixin from './mixins/GeneralMixin'
Vue.mixin(GeneralMixin)

import paginationMixin from './mixins/paginationMixin'
Vue.mixin(paginationMixin)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
