import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/plugins/axios'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    CL_W_H_USER: null,

    //pagination length
    PAGE_SETTINGS: {
      orders: {
        page_length: 100,
      },
      vendors: {
        page_length: 100,
      },
      employees: {
        page_length: 100,
      },
      order_pallets: {
        page_length: 100,
      },
      order_pickups: {
        page_length: 100,
      },
      products: {
        page_length: 100,
      },
      distribution_requests: {
        page_length: 100,
        orders_release     : { page_length: 100 },
      },
      admins: {
        page_length: 100,
      },
      stocks: {
        stocks_inhand : { page_length: 100 },
        stocks_intake : { page_length: 100 },
        stocks_outake : { page_length: 100 },
      },
      roles_and_permissions: {
        roles                   : { page_length: 100 },
        modules                 : { page_length: 100 },
        assign_roles_to_modules : { page_length: 100 },
        assign_permissions      : { page_length: 100 },
      },
    },
  },
  getters: {
    ACCESS_TOKEN(state) {
      return state.CL_W_H_USER?.token ?? undefined
    },
    IS_AUTHENTICATED(state) {
      return state.CL_W_H_USER?.token ? true : false
    },
    CURRENT_USER(state) {
      return state.CL_W_H_USER
    },
    GET_PAGE_LENGTH: (state) => (pageName) => {
      const keys = pageName.split('.');
      let target = state.PAGE_SETTINGS;
      for (const key of keys) {
        if (!target[key]) return 10; // Default page length
        target = target[key];
      }
      return target.page_length || 10; // Return the page length or default
    },
    CURRENT_USER_PERMISSIONS: (state) => state.CL_W_H_USER.role_permissions,
  },
  mutations: {
    setCLUser(state, user) {
      state.CL_W_H_USER = user;
    },


    UPDATE_PAGE_LENGTH(state, { pageName, pageLength }) {
      const keys = pageName.split('.');
      let target = state.PAGE_SETTINGS;
      // Traverse the keys to reach the nested property
      for (let i = 0; i < keys.length - 1; i++) {
        if (!target[keys[i]]) {
          Vue.set(target, keys[i], {}); // Ensure the key exists
        }
        target = target[keys[i]];
      }
      // Set the final key
      Vue.set(target, keys[keys.length - 1], { page_length: pageLength });
    },

    clearCLUser(state) {
        state.CL_W_H_USER = null;
    },
  },
  actions: {
    HTTP_POST_REQUEST({ commit, state }, data) {
      return axios.post(data.url, data.payload).then((response) => {
        return response;
      });
    },
    HTTP_PUT_REQUEST({ commit, state }, data) {
      return axios.put(data.url, data.payload).then((response) => {
        return response;
      });
    },
    HTTP_DELETE_REQUEST({ commit, state }, data) {
      return axios.delete(data.url, data.payload).then((response) => {
        return response;
      });
    },
    HTTP_GET_REQUEST({ commit }, url) {
      return axios.get(url).then((response) => {
        return response;
      });
    },
    HTTP_GET_PAYLOAD_REQUEST({ commit }, data) {
      return axios
        .get(data.url, { data: {}, params: data.payload })
        .then((response) => {
          return response;
        });
    },
    HTTP_GET_PAYLOAD_REQUEST({commit},data)
    {
      return axios
        .get(data.url,{ data:{}, params : data.payload })
        .then(response => { return response })
    },
    
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});
