<template>
  <div>
    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="deleteItemConfirm">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default{
  name : 'ConfirmDelete',
  data : ()=>({
    dialogDelete : true,
  }),
  mounted(){

  },
  methods:{
    deleteItemConfirm(){
      this.$emit('yes',true)
      this.$emit('closed',true)
      this.dialogDelete = false
    },
    closeDelete(){
      this.$emit('canceld',true)
      this.$emit('closed',true)
      this.dialogDelete = false
    },
  }
}
</script>